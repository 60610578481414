<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <v-dialog v-model="qrCodePopup" max-width="400">
      <v-card class="pa-8">
        <div
          class="title center-align mb-4"
          style="font-family: Inter !important;"
        >
          QR-код для оплаты
        </div>
        <div class="d-flex justify-center justify-start mb-4">
          <img
            src="/img/images/logo-sbp.svg"
            style="width: 40px"
            alt="logo"
            class="center-align"
          />
          <span
            style=" font-weight: 800; font-size:22px;font-family: Inter !important;"
            >сбп</span
          >
        </div>
        <div class="d-flex justify-center justify-start mb-4">
          <VueQRCodeComponent
            v-if="qrCode"
            :text="qrCode"
            size="300"
            error-level="H"
          />
        </div>

        <span class="d-flex justify-center" v-if="card_price">
          <span v-if="promocode.checkPromocodes.discount_percent" class="">
            <div
              v-if="promoCode.length == 8 && promocode.errMessages"
              style="color: #EB5C6D"
            >
              {{ promocode.errMessages.detail }}
            </div>

            <div
              v-if="
                promoCode.length == 8 &&
                  promocode.checkPromocodes.discount_percent
              "
              style="color:#505a6d"
              class=""
            >
              Скидка по промокоду:
              {{ promocode.checkPromocodes.discount_percent }}%, -{{
                formatPrice(promocode.checkPromocodes.discount_amount)
              }}<br />
              Итого:
              <span style="color: #95c23d; font-size: 18px;font-weight: 600">
                {{ promocodesAmountResult }}
              </span>
            </div>
          </span>

          <span v-else class="">
            Итого:
            <span style="color: #95c23d; font-size: 18px;font-weight: 600 ">
              {{ showCardPiriceAmountPromocode }}
            </span></span
          >
        </span>
        <span class="d-flex justify-center" v-else>
          <span v-if="promocode.checkPromocodes.discount_percent" class="">
            <div
              v-if="promoCode.length == 8 && promocode.errMessages"
              style="color: #EB5C6D"
            >
              {{ promocode.errMessages.detail }}
            </div>

            <div
              v-if="
                promoCode.length == 8 &&
                  promocode.checkPromocodes.discount_percent
              "
              style="color:#505a6d"
              class=""
            >
              Скидка по промокоду:
              {{ promocode.checkPromocodes.discount_percent }}%, -{{
                formatPrice(promocode.checkPromocodes.discount_amount)
              }}<br />
              Итого:
              <span style="color: #95c23d; font-size: 18px;font-weight: 600">
                {{ promocodesAmountResult }}
              </span>
            </div>
          </span>

          <span v-else class="">
            Итого:
            <span style="color: #95c23d; font-size: 18px;font-weight: 600 ">
              {{ showAppPiriceAmountPromocode }}
            </span></span
          >
        </span>

        <span v-if="!orderStatus" class="d-flex justify-center"
          >Статус оплаты: Ожидается оплата</span
        >
        <span v-else class="d-flex justify-center"
          >Статус оплаты: {{ orderStatus }}</span
        >
      </v-card>
    </v-dialog>

    <div
      v-if="groups && groups.length"
      class="py-7 pr-8 pl-8 list__content-adding"
      style="background-color: #ffffff !important;"
    >
      <div>
        <div
          v-if="$props.type === 'card'"
          class="d-flex justify-space-between align-baseline"
        >
          <div class="object__term-text" style="font-size: 26px">Карта</div>
          <slot name="button"></slot>
        </div>

        <div
          v-if="$props.type === 'app'"
          class="d-flex justify-space-between align-baseline"
        >
          <div class="object__term-text" style="font-size: 26px">
            Доступ по приложению
          </div>
          <slot name="button"></slot>
        </div>
        <p
          v-if="$props.type === 'app' && groups.length > 1"
          class="object__term-text"
          style="margin-bottom: 6px"
        >
          Тарифы
        </p>

        <p v-if="$props.type === 'card'" class="">
          <span class="d-flex justify-space-between">
            <span class="object__text-rate">Стоимость карты :</span>

            <span class="mr-4"
              ><b>{{ card_price }}.00</b> ₽</span
            >
          </span>
          <span class="d-flex justify-space-between">
            <span class="object__text-rate">Срок действия :</span>
            <span class="mr-4"
              ><b style="margin-left: 11px">{{ duration }}</b> мес.</span
            >
          </span>
        </p>
        <p
          v-if="$props.type === 'card'"
          class="object__term-text mt-3 pt-3"
          style="border-top: 1px solid #ccd6e499"
        >
          Промокод
        </p>
        <div v-if="card_price" class="">
          <div class="object__block-term d-flex">
            <ValidationInputField
              label="Введите промокод"
              v-model="promoCode"
              class="mail"
              validate-name="ввода промокода"
              mask="XXXXXXXXX"
            />
          </div>
        </div>

        <div
          v-if="card_price && promoCode.length == 8 && promocode.errMessages"
          style="color: #EB5C6D"
        >
          {{ promocode.errMessages.detail }}
        </div>
        <div
          v-if="
            card_price &&
              promoCode.length == 8 &&
              promocode.checkPromocodes.discount_percent
          "
          style="color:#505a6d"
          class="mt-6 d-flex flex-column"
        >
          <div class="d-flex justify-space-between">
            <span class="object__text-rate ">Скидка:</span>
            <span class="mr-4" style="color: rgb(235, 92, 109) ">
              <b style="color: black">
                {{ promocode.checkPromocodes.discount_percent }}% ,
              </b>
              <b>
                {{ formatPrice(promocode.checkPromocodes.discount_amount) }}
              </b>
            </span>
          </div>
          <div class="d-flex justify-space-between">
            <span class="object__text-rate">Итого к оплате:</span>

            <span class="mr-4" style="color: #95c23d">
              <b>
                {{ formatPrice(promocode.checkPromocodes.result_amount) }}
              </b>
            </span>
          </div>
        </div>

        <p v-if="$props.type === 'card'" class="object__term-text mt-6">
          E-mail для отправки чека
        </p>
        <div v-if="card_price" class=" d-flex ">
          <div class="object__block-term d-flex ">
            <ValidationInputField
              rules="required|email"
              v-model="email"
              class="mail"
              label="E-mail"
              validate-name="e-mail"
            />
          </div>
        </div>

        <div
          v-if="$props.type === 'app' && groups.length > 1"
          class="object__block-form-rate"
        >
          <div v-for="group in groups" :key="group.id">
            <div class="object__item">
              <div class="object__block-rate">
                <div class="" style="display: flex">
                  <label class="radio-wrap">
                    <input
                      v-model="groupSelected"
                      :value="group.id"
                      type="radio"
                      class="radio_none"
                      name="groupSelected"
                    />
                    <div class="radio"></div>
                  </label>

                  <p class="object__point" :title="group.title">
                    {{ group.title ? group.title : group.id }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="prices && $props.type === 'app'" class=" d-flex">
          <div style="width: 100%">
            <p class="object__term-text">Выберите период</p>

            <div
              class="object__block-term d-flex justify-space-between flex-column"
            >
              <div class="d-block">
                <label
                  v-if="sourceObject.results.free_trial && freeTrialDays < 0"
                  class="radio-wrap "
                >
                  <input
                    v-if="$props.type === 'app'"
                    v-model="app_price"
                    :value="free_trial"
                    type="radio"
                    class="radio_none"
                    name="app_price"
                  />
                  <div class="radio mb-12"></div>
                  <p
                    style="max-width: 115px"
                    class="radio_text object__term-months-text"
                  >
                    Пробный период (2 месяца)
                  </p>
                </label>
              </div>

              <div class="d-inline-flex" style="grid-gap:70px">
                <label
                  v-for="price in prices"
                  :key="price.id"
                  class="radio-wrap d-flex"
                >
                  <input
                    v-if="$props.type === 'app'"
                    v-model="app_price"
                    :value="price.id"
                    type="radio"
                    class="radio_none"
                    name="app_price"
                  />
                  <input
                    v-else
                    v-model="card_price_id"
                    :value="price.id"
                    type="radio"
                    class="radio_none"
                    name="card_price"
                  />

                  <div class="radio mb-12"></div>

                  <div
                    :class="{
                      'object__term-text-wrap_discount': price.discount
                    }"
                    class="object__term-text-wrap"
                  >
                    <p
                      v-if="price.duration == 3"
                      class="radio_text object__term-months-text"
                    >
                      Срок действия {{ price.duration }} мес.
                    </p>
                    <p v-else class="radio_text object__term-months-text">
                      Срок действия 1 год
                    </p>
                    <!--                    <div v-if="price.discount">-->
                    <!--                      <p class="object__term-price-text">-->
                    <!--                        {{ formatPrice(price.cost_with_discount) }}-->
                    <!--                      </p>-->
                    <!--                      <p class="object__term-rate-text">-->
                    <!--                        {{-->
                    <!--                          formatPrice(-->
                    <!--                            price.cost_with_discount / price.duration-->
                    <!--                          )-->
                    <!--                        }}/мес.-->
                    <!--                      </p>-->
                    <!--                    </div>-->
                    <div>
                      <!--                      <p class="object__term-discount-text">Без скидки</p>-->
                      <p class="object__term-price-text">
                        {{ formatPrice(price.price) }}
                      </p>
                      <p class="object__term-rate-text">
                        {{ formatPrice(price.price / price.duration) }}/мес.
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="prices && app_price != 1 && app_price" class=" d-flex ">
          <div style="width: 100%">
            <div class="mb-6">
              <p
                v-if="$props.type === 'app'"
                class="object__term-text mt-3 pt-3"
              >
                Промокод
              </p>
              <div v-if="app_price" class="">
                <div class="object__block-term d-flex">
                  <ValidationInputField
                    label="Введите промокод"
                    v-model="promoCode"
                    class="mail"
                    validate-name="ввода промокода"
                    mask="XXXXXXXXX"
                    :disabled="!app_price"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="promoCode.length == 8 && promocode.errMessages"
              style="color: #EB5C6D"
            >
              {{ promocode.errMessages.detail }}
            </div>

            <div
              v-if="
                app_price &&
                  promoCode.length == 8 &&
                  promocode.checkPromocodes.discount_percent
              "
              style="color:#505a6d"
              class="mt-6 d-flex flex-column"
            >
              <div class="d-flex justify-space-between">
                <span class="object__text-rate ">Скидка:</span>
                <span class="mr-4" style="color: rgb(235, 92, 109) ">
                  <b style="color: black">
                    {{ promocode.checkPromocodes.discount_percent }}% ,
                  </b>
                  <b>
                    {{ formatPrice(promocode.checkPromocodes.discount_amount) }}
                  </b>
                </span>
              </div>
              <div class="d-flex justify-space-between">
                <span class="object__text-rate">Итого к оплате:</span>

                <span class="mr-4" style="color: #95c23d">
                  <b>
                    {{ promocodesAmountResult }}
                  </b>
                </span>
              </div>
            </div>

            <p v-if="$props.type === 'app'" class="object__term-text mt-6">
              E-mail для отправки чека
            </p>
            <div v-if="app_price" class=" d-flex ">
              <div class="object__block-term d-flex ">
                <ValidationInputField
                  rules="required|email"
                  v-model="email"
                  class="mail"
                  label="E-mail"
                  validate-name="e-mail"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class=" sbp d-flex end mt-6 "
          style=";flex-direction: row-reverse ; align-items: center; border-top: 1px solid rgba(204, 214, 228, 0.6); padding-top: 1rem"
        >
          <button
            v-if="!app_price && !card_price"
            @click="changeFreeTrial"
            class="btn btn-green ml-6"
          >
            Активировать пробный период
          </button>
          <v-btn
            v-if="
              (prices && app_price != 1 && app_price) ||
                card_price ||
                card_price
            "
            @click="handleSubmit(onPaymentSubmit)"
            class="btn btn_color_white spacebtn"
            style="min-width: 184px;"
          >
            Оплатить
          </v-btn>
        </div>
      </div>
    </div>
  </ValidationForm>
</template>

<script>
import {
  postUserPaymentsRequest,
  postUserPaymentsSPBRequest
} from "@/helpers/api/user-payments";
import VueQRCodeComponent from "vue-qrcode-component";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";

import moment from "moment";
import { getPaymentByIdRequest } from "@/helpers/api/payments";

export default {
  name: "MyObjectsForm",
  components: {
    ValidationInputField,
    ValidationForm,
    VueQRCodeComponent
  },
  props: {
    data: {
      type: Object
    },
    groups: {
      type: Array
    },
    type: {
      type: String,
      default: "app"
    }
  },
  data: () => ({
    app_price: 1,
    showAppPirice: "",
    card_price_id: null,
    card_price: null,
    duration: null,
    groupSelected: null,
    promoCode: "",
    free_trial: false,
    dialog: false, // окно полосы загрузки
    qrCode: null,
    qrCodePopup: false,
    orderStatus: "",
    paymentId: null,
    newInterval: null
  }),
  computed: {
    promocode() {
      return this.$store.state.promocode;
    },
    freeTrialDays() {
      return moment(this.data.app_date_end * 1000).diff(moment(), "day") * 1;
    },
    promocodesAmountResult() {
      return this.formatPrice(
        this.$store.state.promocode.checkPromocodes.result_amount
      );
    },

    showAppPiriceAmountPromocode() {
      return this.formatPrice(this.showAppPirice);
    },
    showCardPiriceAmountPromocode() {
      return this.formatPrice(this.card_price);
    },

    sourceObject() {
      return this.$store.getters.getUserInfo;
    },

    email: {
      get() {
        return this.$store.getters.getUserProfileForm.email.value;
      },
      set(newValue) {
        this.$store.commit("changeEditProfileValue", {
          fieldName: "email",
          value: newValue
        });
      }
    },
    groupCurrent() {
      let result = null;
      if (this.groups.length <= 1) {
        result = this.groups[0];
      } else {
        this.groups.map(item => {
          if (item.id === this.groupSelected) result = item;
        });
      }
      return result;
    },
    prices() {
      if (this.groupCurrent && this.$props.type === "app")
        return this.groupCurrent.app_prices;

      if (this.groupCurrent && this.$props.type === "card")
        return this.groupCurrent.card_prices;
      return null;
    }
  },
  mounted() {
    if (this.type == "card") {
      this.card_price_id = this.groups[0].card_prices[0].id;
      this.card_price = this.groups[0].card_prices[0].price;
      this.duration = this.groups[0].card_prices[0].duration;
    }
  },
  watch: {
    app_price: function() {
      let temp = this.prices.filter(el => {
        return el.id == this.app_price;
      });
      this.showAppPirice = this.formatPrice(temp[0].cost_with_discount);
      let data = {
        promoCode: this.promoCode,
        app_price: this.app_price,
        card_price: this.card_price_id
      };
      if (data.promoCode.length == 8 && this.app_price) {
        this.$store.dispatch("fetchCheckPromocodes", { data });
      } else {
        this.$store.dispatch("clearCheckPromocode");
        this.promoCode = "";
      }
    },
    promoCode: function(val) {
      let data = {
        promoCode: this.promoCode,
        app_price: this.app_price ? this.app_price : null,
        card_price: this.card_price_id ? this.card_price_id : null
      };
      if (
        (val.length == 8 && this.app_price) ||
        this.app_price != this.app_price
      ) {
        this.$store.dispatch("fetchCheckPromocodes", { data });
      } else if (val.length == 8 && this.card_price) {
        this.$store.dispatch("fetchCheckPromocodes", { data });
      } else {
        this.$store.dispatch("clearCheckPromocode");
        this.promoCode = "";
      }
    },
    qrCodePopup: function(val) {
      if (val == false) {
        clearInterval(this.newInterval);
      }
    }
  },
  created() {
    this.$store.dispatch("fetchUserProfile").finally(() => {
      this.isDateLoaded = false;
    });
    this.$store.dispatch("fetchUserInfo").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.isDateLoaded = true;
      this.$store.dispatch("fetchUserInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  methods: {
    changeFreeTrial() {
      this.$store.dispatch(
        "freeTrial",
        this.groupSelected || this.groups[0].id
      );
    },
    formatPrice(price) {
      return price.toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB"
      });
    },

    onPaymentSubmit() {
      this.$store
        .dispatch("editUserProfileFromPaymentAccess", {
          id: this.$store.getters.getUserId
        })
        .then(() => {
          this.loading = true;
          postUserPaymentsRequest({
            app_price: this.app_price != 1 ? this.app_price : null,
            card_price: this.card_price_id ? this.card_price_id : null,
            coupon_code: this.promoCode ? this.promoCode : ""
          }).then(response => {
            let { payment_redirect, url } = response.data;

            if (payment_redirect && url) window.location = url;
          });
        })
        .catch(error => {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: `${error.errors[0].message}`
              }
            ]
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPaymentSPBSubmit() {
      this.$store
        .dispatch("editUserProfileFromPaymentAccess", {
          id: this.$store.getters.getUserId
        })
        .then(() => {
          this.loading = true;
          postUserPaymentsSPBRequest({
            app_price: this.app_price != 1 ? this.app_price : null,
            card_price: this.card_price_id ? this.card_price_id : null,
            coupon_code: this.promoCode ? this.promoCode : ""
          })
            .then(response => {
              this.qrCode = response.data.url;
              this.qrCodePopup = true;
              this.paymentId = response.data.payment.id;
              let { payment_redirect, return_url } = response.data;
              this.newInterval = setInterval(
                () =>
                  getPaymentByIdRequest({ id: this.paymentId })
                    .then(response => {
                      this.orderStatus = response.data.status_display;
                      if (this.qrCodePopup == false) {
                        clearInterval(this.newInterval);
                      }
                      if (
                        this.orderStatus == "Завершен" ||
                        this.orderStatus == "Требуется ввод карты"
                      ) {
                        this.qrCodePopup = false;
                        clearInterval(this.newInterval);
                        if (payment_redirect && return_url)
                          window.location = return_url;
                      }
                    })
                    .catch(() => {
                      clearInterval(this.newInterval);
                    }),
                5000
              );
            })
            .catch(() => {
              clearInterval(this.newInterval);
            });
        })
        .catch(error => {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: `${error.errors[0].message}`
              }
            ]
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.mail {
  width: 15rem !important;
}

.list__content-adding .object__block-form-rate .object__content-info {
  display: block;
}

.list__content-adding .object__item-info {
  width: auto;
  display: inline-block;
  padding: 5px 15px;
  margin: 0px 5px 0px 0px;
  line-height: 1;
  max-width: 100%;
  overflow: hidden;
  height: auto;
}

.object__item-bg {
  background: #f5f5f5;
}

.list__content-adding .object__block-term {
  grid-gap: 0px;
}

.object__card-term-text {
  padding: 20px 0 0 40px;
}

.list__content-adding .object__block-form-rate {
  grid-template-columns: 1fr !important;
  margin-bottom: 24px;
}

.list__content-adding .object__block-form-rate .object__item {
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
  min-height: 58px;
}

.list__content-adding .object__block-form-rate .object__block-rate {
  width: 100%;
  display: flex;
}

.list__content-adding .object__block-form-rate {
  padding: 0;
  background-color: inherit;
  grid-gap: 0;
}

@media screen and (max-width: 650px) {
  .mail {
    max-width: max-content;
  }
  .sbp {
    flex-direction: column-reverse !important;
    margin: 0;
    align-items: inherit !important;
  }
  .spacebtn {
    margin: 16px 0;
  }
}
</style>
