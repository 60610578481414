<template>
  <div class="card object" :class="'card-' + data.type">
    <div class="object__item card__header">
      <div
        class="object__identifier"
        :class="'object__identifier_' + type.class"
      ></div>
      <img :src="type.icon" class="object__icon-main" />
      <p class="object__text-name">
        {{ data.object_name || data.organization_name }}
      </p>
      <img
        src="/img/icons/object__icon-placemark.svg"
        alt="object__icon-placemark"
        class="object__icon-placemark"
      />
      <p class="object__icon-address">{{ data.address }}</p>
    </div>

    <MyObjectTarifInfo :data="data" />
  </div>
</template>

<script>
import {
  getObjectAccessibleGroupsByIdRequest,
  getObjectInaccessibleGroupsByIdRequest
} from "@/helpers/api/objects";

import moment from "moment";

import MyObjectTarifInfo from "@/components/info/myObjects/MyObjectTarifInfo";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";

export default {
  name: "MyObjectsCard",
  components: {
    MyObjectTarifInfo
  },
  props: {
    data: {
      type: Object
    }
  },
  data: () => ({
    isCardShowContinue: false,
    isShowContinue: false,
    accessibleGroups: [],
    inaccessibleGroups: [],
    isShowChangeTarif: false,
    buyCardShowContinue: false, // покупка карты
    lostCardShowContinue: false, // карта утеряна

    disabled: true,
    cardNumber: null, // добавление существующей карты
    clickObject: null //объект детского садика по которому кликнули
  }),

  watch: {
    cardNumber: function(val) {
      if (val.length == 9) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    }
  },

  methods: {
    formatedDate(date) {
      return moment(date, "X").format("DD.MM.YYYY");
    },
    daysLeft(date) {
      moment.locale("ru");
      if (moment(date, "X").diff(moment()) > 0)
        return moment(date, "X").fromNow(true);
      return null;
    },
    getAccessibleGroups(id) {
      getObjectAccessibleGroupsByIdRequest({
        id
      }).then(response => {
        this.accessibleGroups = response.data.results;
      });
    },
    getInaccessibleGroups(id) {
      getObjectInaccessibleGroupsByIdRequest({
        id
      }).then(response => {
        this.inaccessibleGroups = response.data.results;
      });
    },

    addExistingCard(number) {
      const objId = this.clickObject.id;
      this.$store
        .dispatch("addExistingCardByUser", { number, objId })
        .then(() => {
          this.buyCardShowContinue = false;
          this.$emit("reloadData");
        });
    },

    lostCard() {
      const cardNumber = this.info.card_code;
      const currentCardId =
        this.$store.getters.getCurrentUser?.cards.filter(
          el => el.code == cardNumber
        )[0].id || this.$store.getters.getCurrentUser.cards[0].id;
      this.$store.dispatch("lostCardUser", { id: currentCardId }).then(() => {
        this.lostCardShowContinue = false;
        this.$emit("reloadData");
      });
    }
  },
  computed: {
    type() {
      let result = {
        class: "color_blue",
        icon: "/img/icons/object__icon-main.svg",
        title: dictionariesHelper.objectType[this.data.type]
      };

      // [ other, business-center, bank, educational-institution, industrial-enterprise, protected-zone, parking, traffic-lane, housing-estate, community-office, apartment-house, lift, kindergarten ]
      switch (this.$props.data.type) {
        case "kindergarten":
          result.icon = "/img/icons/object__icon-pyramid.svg";
          result.class = "color_green";
          break;
      }

      return result;
    },
    info() {
      return this.$props.data;
    },
    availableAccesses() {
      let { available_accesses } = this.info;
      return available_accesses;
    },
    controllersPaidAccess() {
      let { controllers_paid_access } = this.info;
      return controllers_paid_access;
    },
    controllersGuestAccess() {
      let { controllers_guest_access } = this.info;
      return controllers_guest_access;
    }
  }
};
</script>

<style scoped>
.card {
  overflow: visible;
}

.card__header {
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}

.object__item-info {
  white-space: nowrap;
  padding: 0px 15px;
}

.list__content-adding .object__point {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px 15px;
  text-overflow: ellipsis;
  display: inline;
  line-height: 1;
}

.disabled {
  pointer-events: none;
  border-color: #acacac;
  color: #acacac;
}
</style>
