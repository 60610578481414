<template>
  <MainLayout  class="list_radius-all">
    <div v-if="sourceObject" class="list__content-adding">
      <div class="title title_margin_none card">
        <p v-if="sourceObject.count != 0" class="title__text">Всего: {{ sourceObject.count }}</p>
        <a v-if="sourceObject.count != 0" @click="toggleAccountAddPopup" class="btn btn-add btn_color_green"
        >Добавить объект</a
        >
        <div v-if="sourceObject.count == 0" class="align-center" style="width: 100%; height: 70vh;">
          <div class=" d-flex flex-column top" >
            <pre class="object__text-term text" >У вас нет доступных объектов. Для получения доступа обратитесь к администратору вашего объекта
              или получите доступ по лицевому счету</pre>

            <pre @click="toggleAccountAddPopup" class="btn btn-add btn_color_green mt-4 pa-7"
                 style="width: max-content;line-height: 1.3; align-self: center; margin-left:0"
            >
              <span class="mt-1 ml-2" style="text-align: center">Добавить объект
по лицевому счету</span>
            </pre>
          </div>

        </div>

        <v-dialog max-width="550" v-model="accountPopup">
          <div class="authoriz">
            <div class="authoriz__item authoriz__item-inputs pb-6">
              <div class="authoriz__title">
                <p class="authoriz__title-text flex-left">Добавление объекта</p>
              </div>
              <div>
                <ValidationForm v-slot:default="{ handleSubmit }">
                  <v-row>
                    <v-col :cols="12">
                      <ValidationAutocompleteField
                          label="Город"
                          v-model="selectCity"
                          :search-function="citySearch"
                          validate-name="город"

                      />
                    </v-col>
                    <v-col :cols="12" style="margin: 1rem 0">
                      <ValidationAutocompleteField
                          :disabled="!selectCity"
                          :search-function="searchFunction"
                          v-model="selectedObject"
                          label="Объект"
                      />
                    </v-col>
                    <v-col :cols="12">
                      <ValidationInputField
                          :disabled="!selectCity"
                          v-model="selectedAccount"
                          label="Лицевой счет"
                      />
                    </v-col>
                  </v-row>
                  <div class="mt-12 button-mobile d-flex flex-row justify-end">
                    <a
                        @click="toggleAccountAddPopup"
                        class="btn btn_color_white"
                    >
                      Отмена
                    </a>
                    <v-btn
                        type="submit"
                        @click="handleSubmit(createAccount)"
                        class="btn button-mobile__save btn-green ml-2 "
                    >
                      Сохранить
                    </v-btn>
                  </div>
                </ValidationForm>
              </div>
            </div>
          </div>
        </v-dialog>
      </div>
      <div v-if="sourceObject.count != 0" class="card-wrap">
        <MyObjectsCard
            v-for="item in sourceObject.results"
            :key="item.id"
            :data="item"
            @reloadData="reloadData"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import MyObjectsCard from "@/components/info/myObjects/MyObjectsCard";
import * as query from "@/helpers/query";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import Axios from "axios";
import { getObjectsAddressRequest } from "@/helpers/api/objects";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import {getCitiesTypesClearRequest} from "@/helpers/api/cities";

export default {
  name: "MyObjectsPage",
  data() {
    return {
      queries: query.objects,
      type: "default",
      accountPopup: false,
      selectedObject: null,
      selectedAccount: null,
      selectCity: null,

      searchFunction: async value => {

        return (await getObjectsAddressRequest({search: value, city: this.selectCity})).data.results
            .map(el => ({
              text: el.name,
              value: el.id
            }))
            .sort((a, b) => {
              return b.text - a.text;
            });
      },
      citySearch: async value => {
        return (
            await getCitiesTypesClearRequest({
              search: value,
              offset: null
            })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
    };
  },
  components: {
    ValidationAutocompleteField,
    ValidationForm,
    ValidationInputField,
    MainLayout,
    MyObjectsCard
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getPageObjectsExtendedList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearObjectPage").then(() => next());
  },
  methods: {
    toggleAccountAddPopup() {
      this.accountPopup = !this.accountPopup;
    },
    createAccount() {
      Axios.post(`/users/${this.$store.getters.getUserId}/link_to_account/`, {
        object: this.selectedObject,
        account: this.selectedAccount
      }).then(() => {
        this.selectedAccount = null;
        this.selectedObject = null;
        this.isDateLoaded = true;
        this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {
          this.isDateLoaded = false;
        });
      });
    },
    reloadData() {
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageObjectExtendedList").finally(() => {
        this.isDateLoaded = false;
      });
    }
  }
};
</script>

<style scoped>
.btn-add:before{
  height: 16px;
  width: 16px;
}
.text {
  text-align: center;
  align-self: center;
  font-size: 20px ;
  line-height: 1.3;
  text-wrap: wrap;
  display: -ms-flexbox;
  display: -webkit-box;
  display:flex;
  flex: 0 1 50%;
  white-space: pre-wrap
}
.top{
  margin-top: 30vh
}
@media screen and (max-width: 650px) {
  .top{
    margin-top: 10vh
  }
  .button-mobile {
    flex-direction: column !important;
    margin: 0;
    align-items: inherit !important;
  }

  .button-mobile__save {
    margin: 0 !important;
    margin-top: 1rem !important;
  }

  .authoriz {
    margin: 0px !important;
  }
}
</style>
