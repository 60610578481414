import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getUserPaymentsRequest({ query }) {
  return Axios.get(
    getQueriedUrl({
      url: "/users/",
      query: {
        ...query,
        "optional-fields": [
          "group",
          "object",
          "organization",
          "user_details",
          "diller_name",
          "date_end"
        ]
      }
    })
  );
}
export async function getUserPaymentsByIdRequest({ id }) {
  return Axios.get(`/user-payments/${id}`);
}
export async function postUserPaymentsRequest(data) {
  return Axios.post(`/user-payments/`, data);
}
export async function postUserPaymentsSPBRequest(data) {
  return Axios.post(`/user-payments/pay-by-sbp/`, data);
}
